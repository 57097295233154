import BaseActionCreator from "factory/BaseActionCreator";
import {CalendarEvents} from "actions/Events";

const STATUS_CODE_MESSAGES = {
  8033: "No Tickets Available",
  // Add more status codes and messages as needed
};


class CalendarActionCreator extends BaseActionCreator{

  constructor() {
    super("json");
  }

  loadSeatingChartById(seatingChartId){
    return this.get("/SeatingChart("+seatingChartId+")").then((data) => {
      this.dispatch({
        type: CalendarEvents.SEATING_CHART_BY_ID_LOADED,
        data
      });
      return data;
    });
  }

  loadSeatingChart(performanceId, clientId, deepLink = false, setErrorMessage) {
    let params = '';
    if(deepLink){
      params = "?deepLink=true"
    }
    return this.get(`/Performance(${performanceId})/seatingChart${params}`, null, {"clientId": clientId, "newCIRequest":true}).then(data => {
      let priceLevels = data.priceLevels;
      data.sections.forEach(section =>
        section.rows.forEach(row =>
          row.seats.forEach(seat => seat.priceLevel = priceLevels[seat.priceLevel])
        )
      );
      setErrorMessage(null);
      this.dispatch({type:CalendarEvents.SEATING_CHART_LOADED,data:data});
      return data;
    },(data)=>{
      let errorMessage = STATUS_CODE_MESSAGES[data.data.statusCode] || data.data.message || "No Tickets Available";
      setErrorMessage(errorMessage);
      this.dispatch({type:CalendarEvents.NO_SEATING_CHART,data});
    });
  }
}

export default new CalendarActionCreator();
