import { createContext } from 'react'

export const TicketContext = createContext({
	selectedTicket: null,
	setSelectedTicket: () => {},

	selectedPromo: null,
	setSelectedPromo: () => {},

	ticketForPricing: null,
	setTicketForPricing: () => {},

	selectedTicketTitle: null,
	setselectedTicketTitle:() => {}
})
