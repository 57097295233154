import React from 'react'

// Libraries
import styled from 'styled-components'

// Components
import SelectSeatGeneralInfo from './SelectSeatGeneralInfo'

// Styling
import { theme, colors } from 'theme'
import SelectSeatPriceInfo from './SelectSeatPriceInfo'

const PopUpHeader = styled.div`
	display: flex;
	justify-content: space-between;

	padding-bottom: ${theme.padding.smmd};
	margin-bottom: ${theme.padding.smmd};

  border-bottom: 1px solid ${colors.outlines.outline};
`

const SelectSeatDetails = ({ closePopup, selectedSeat }) => {
	return (
		<>
			<PopUpHeader>
				<SelectSeatGeneralInfo selectedSeat={selectedSeat} />

				<SelectSeatPriceInfo closePopup={closePopup} />
			</PopUpHeader>

		</>
	)
}

export default SelectSeatDetails
