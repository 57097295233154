import React, { useContext, useEffect } from 'react'

import { TicketContext } from '../ticketContext/TicketContext'
import { Container, TicketEl, TicketName } from './SharedTicketElements'

const Promotion = ({
	promo,
	isFirstTicket = true,
}) => {
	const ticketContext = useContext(TicketContext)

	const { selectedTicket, setSelectedPromo, selectedPromo, setTicketForPricing } = ticketContext

	useEffect(() => {
		setDefaults()
	}, [])

	const setDefaults = () => {
		if (!isFirstTicket) return
		
		ticketContext.setSelectedPromo({ ...promo, name: 'Full price' }) // Set the promo to default on ticket
	}

	const handlePromoClick = () => {
		ticketContext.setSelectedPromo(promo)
		setTicketForPricing(promo?.name !== 'Full price' ? promo : selectedTicket)
	}

	const isPromoSelected = () => {
		return selectedPromo?.name == promo?.name
	}

	return (
		<TicketEl onClick={handlePromoClick} selected={isPromoSelected()}>
			<Container>
				<TicketName>{promo?.name || ""}</TicketName>
			</Container>
		</TicketEl>
	)
}

export default Promotion
