import React from 'react'
import { diff } from 'deep-object-diff'
import { connect } from 'react-redux'
import 'sass/components/seatingChart/Seat.scss'
import {
	SEAT_RADIUS,
	SEAT_STROKE,
	SEAT_SIZE,
} from 'components/seatingChart/shared/SeatingChartConfig'
import {
	SeatCartIcon,
	SeatHandicap,
	SeatSelectedIcon,
} from 'components/common/icons/SvgIcons'
import { OtColors } from 'util/OtColors'
import { selectTemporarySeat } from 'components/seatingChart/actions'

import {
	getSelectedSeats,
	getTemporarySelectedSeat,
} from 'components/seatingChart/selectors'

export class Seat extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selected: props.selected || false,
			isDragging: false,
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		if (typeof newProps.selected !== 'undefined') {
			this.setState({
				selected: newProps.selected,
			})
		}
		if (this.state.selected && newProps.data && !newProps.data.available) {
			this.setState(
				{
					selected: false,
				},
				() => {
					if (typeof newProps.onSeatUnselect === 'function') {
						this.props.onSeatUnselect(newProps.data, this)
					}
				}
			)
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const s = diff(nextState, this.state)
		if (Object.keys(s).length !== 0) {
			return true
		}
		return Object.keys(diff(nextProps, this.props)).length !== 0
	}

	filterSeat = (seat) => {
		let {
			seatsToSelect,
			onlySeatSelection,
			selectedSeats,
			priceLevelFilters,
			filtersLength,
		} = this.props
		if (onlySeatSelection && typeof seatsToSelect === 'number') {
			const numToSelect = seatsToSelect - Object.keys(selectedSeats).length
			return numToSelect > 0 || selectedSeats[seat.id]
		}
		if (priceLevelFilters && priceLevelFilters.length > 0) {
			return (
				!seat.priceLevel || priceLevelFilters.indexOf(seat.priceLevel.id) >= 0
			)
		} else if (
			priceLevelFilters &&
			priceLevelFilters.length === 0 &&
			filtersLength !== undefined &&
			filtersLength === 0
		) {
			return false
		}
		return true
	}

	passesFilter = () => {
		return this.filterSeat(this.props.data)
	}

	canSelect = () => {
		let { data, isKillSeat, disabledSeatsForFixedPlusPackage } = this.props
		if (disabledSeatsForFixedPlusPackage) {
			return (
				(!isKillSeat && data.available && this.state.selected) || data.inCart
			)
		}
		return (!isKillSeat && data.available) || data.inCart
	}

	cancelClick(evt) {
		if (!this.props.disableSeatSelect) {
			evt.stopPropagation()
			evt.preventDefault()
		}
	}

	updateCoordinates = () => {
		this.setState({
			isDragging: true,
		})
	}

	select = (evt) => {
		if (this.props.isFixedPackageSeating) {
			evt.preventDefault()
		}
		evt.stopPropagation()

		const absoluteX = window.innerWidth / 2;
		const absoluteY = window.innerHeight / 2;

		const coordinates = [absoluteX, absoluteY]
		if (!this.state.isDragging) {
			if (this.props.disableSeatSelect) {
				return
			}
			if (
				!this.canSelect() ||
				this.props.data.inCart ||
				this.props.disableSeatSelect
			) {
				return
			}
			const newSelectState = !this.state.selected
			if (newSelectState) this.props.onselectTemporarySeat(this.props.data)
			this.setState({ temporarySelected: newSelectState }, () => {
				if (newSelectState && typeof this.props.onSeatSelect === 'function') {
					this.props.onSeatSelect(this.props.data, this, coordinates)
				} else if (
					!newSelectState &&
					typeof this.props.onSeatUnselect === 'function'
				) {
					this.props.onSeatUnselect(this.props.data, this)
				}
			})
		}
		this.setState({
			isDragging: false,
		})
	}

	renderIcons(priceLevel) {
		let { selected } = this.state
		let {
			isInCart,
			isKillSeat,
			data: { available },
			textRotation,
		} = this.props

		if (selected)
			return (
				<g
					onTouchStart={
						this.props.isSmallDevice ? this.select.bind(this) : () => {}
					}
					onClick={
						!this.props.isSmallDevice ? this.select.bind(this) : () => {}
					}
				>
					<SeatSelectedIcon
						className='seat_cart'
						color={OtColors.white}
						rotation={0}
						size={SEAT_RADIUS * 2}
					/>
				</g>
			)

		if (isInCart)
			return (
				<SeatCartIcon
					className='seat_cart'
					color={available ? OtColors.gray : OtColors.white}
					rotation={textRotation}
					size={SEAT_RADIUS * 2}
				/>
			)

		if (isKillSeat)
			return (
				<line
					transform={`rotate(45,${SEAT_SIZE / 2},${SEAT_SIZE / 2})`}
					x1={SEAT_STROKE}
					y1={SEAT_SIZE / 2}
					x2={SEAT_SIZE - SEAT_STROKE}
					y2={SEAT_SIZE / 2}
					strokeWidth={SEAT_STROKE}
				/>
			)

		if (priceLevel) {
			switch (priceLevel.type) {
				case 'HANDICAP':
					return (
						<g
							onTouchStart={
								this.props.isSmallDevice ? this.select.bind(this) : () => {}
							}
							onClick={
								!this.props.isSmallDevice ? this.select.bind(this) : () => {}
							}
						>
							<SeatHandicap
								color={available ? OtColors.white : OtColors.gray}
								rotation={textRotation}
								size={SEAT_RADIUS * 2}
							/>
						</g>
					)

				case 'WHEELCHAIR_COMPANION':
				case 'WHEELCHAIRCOMPANION':
					return (
						<g
							rotation={textRotation}
							onTouchMove={
								this.props.isSmallDevice
									? this.updateCoordinates.bind(this)
									: () => {}
							}
							onTouchEnd={
								this.props.isSmallDevice ? this.select.bind(this) : () => {}
							}
							onClick={
								!this.props.isSmallDevice ? this.select.bind(this) : () => {}
							}
						>
							<text
								x={SEAT_RADIUS / 2}
								y={SEAT_RADIUS * 1.5}
								className={`seat_companion`}
								style={{
									transform: `rotation(${textRotation})`,
									fill: available ? OtColors.white : OtColors.gray,
								}}
							>
								<tspan style={{ fontWeight: 500 }}>C</tspan>
							</text>
						</g>
					)
			}
		}
	}

	isObjectEmpty = (Obj) => {
		for (let key in Obj) {
			if (Object.prototype.hasOwnProperty.call(Obj, key)) return false
		}
		return true
	}

	render() {
		let {
			data: { available, priceLevel, inCart, rowNumber, row, sectionName },
			disableClick,
			isKillSeat,
			textRotation,
			temporarySelectedSeat,
		} = this.props

		let { selected } = Object.prototype.hasOwnProperty.call(this.props, this.props.data.id)

		let isTemporarySelectedseat =
			!this.isObjectEmpty(temporarySelectedSeat) &&
			temporarySelectedSeat.id === this.props.data.id
		let passFilter = this.passesFilter()
		let color = priceLevel ? priceLevel.color : '#888'
		let className = 'ot_seat'
		if (isKillSeat) className += ' killSeat'
		if (selected) className += ' selected'

		if (passFilter && !disableClick) {
			if (this.canSelect()) className += ' selectable'
			else className += ' unselectable'
		} else if (!available && !inCart) className += ' unselectable'
		if (inCart) {
			className += ' inCart'
		}
		if (!passFilter) className += ' filteredOut'

		const seatName = `${sectionName}: ${row} ${rowNumber}`

		const iconRotation =
			textRotation < 0 ? Math.abs(textRotation) : textRotation * -1

		return (
			<g
				width={SEAT_RADIUS * 2}
				height={SEAT_RADIUS * 2}
				className={className}
				transform={`rotate(${iconRotation} ${SEAT_RADIUS} ${SEAT_RADIUS})`}
			>
				<title>{seatName}</title>
				<filter id='offset'>
					<feOffset in='SourceGraphic'></feOffset>
				</filter>
				<circle
					className='bg'
					cx={SEAT_RADIUS}
					//fill={ selected ? color : "white"}
					cy={SEAT_RADIUS}
					r={SEAT_RADIUS - SEAT_STROKE}
					fill={available || inCart ? color : '#222'}
					strokeWidth={SEAT_STROKE}
					onTouchMove={
						this.props.isSmallDevice
							? this.updateCoordinates.bind(this)
							: () => {}
					}
					onTouchEnd={this.select.bind(this)}
					onClick={this.select.bind(this)}
					stroke={available || inCart ? color : '#222'}
				></circle>
				{isTemporarySelectedseat && !this.props.isFixedPackageSeating && (
					<circle
						cx={SEAT_RADIUS}
						cy={SEAT_RADIUS}
						r={SEAT_RADIUS - SEAT_STROKE}
						onTouchEnd={this.select.bind(this)}
						onMouseUp={this.select.bind(this)}
						fill='black'
						opacity='0.4'
						filter='url(#offset)'
					></circle>
				)}
				{this.renderIcons(priceLevel)}
			</g>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	let selectedSeats = getSelectedSeats(state, ownProps)
	selectedSeats = selectedSeats ? selectedSeats : {}

	const temporarySeat = getTemporarySelectedSeat(state, ownProps)
	const temporarySelectedSeat = temporarySeat ? temporarySeat : {}

	return {
		selectedSeats,
		temporarySelectedSeat,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onselectTemporarySeat: (seat) => dispatch(selectTemporarySeat(seat)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Seat)
